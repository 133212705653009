/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;

} 
body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212;
  color: white
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/* navbar */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0 ease-in-out;
}
nav.navbar.scrolled {
  padding: 2%;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
  color: rgb(1, 104, 240);
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .nav.navbar a.nav-link.navbar-link:hover,
nav.navbar .nav.navbar a.nav-link.navbar-link:active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: .3 ease-in-out;
}
.navbar-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* banner */

.banner {
  background-color: #171055;
  margin-top: 0;
  padding: 260px 0 100px 0;
  /* background-image: ; */
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: .8px;
  padding: 8px 10px;
  /* background: ; */
  border: 1px sloid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  color: aliceblue;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: aliceblue;
}
.banner h2{
  color: aliceblue;
}

.banner p {
  color: rgb(220, 201, 201);
  font-size: 18px;
  letter-spacing: .8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #121212;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  margin-bottom: 10px;
  letter-spacing: .8px;
  display: flex;
  align-items: center;
  padding: 2%;
  border-radius: 20px;
}

.banner button:hover {
  background-color: #121212;
  color: white;
}

.banner img {
  animation: updown 3s linear infinite;
  width: 95%;
  border-radius: 5%;
}

/*
@keyframes updown {
  0%{
    transform: translateY(-20px);
  }
  50%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
 */
text-rotate>.wrap {
  border-right: .08em solid #666;
}

/* skills */

.skill {
  padding: 5%;
  /*padding: 0 0 50px 0;*/
  position: relative;
  background: #4A2FBD;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: 60px;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
  color: aliceblue;
  padding-bottom: 15px;
}

.skill h5 {
  color: white;
}

.skill p {
  color: white;
  font-size: 18px;
  letter-spacing: .8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 10%;
}

.skill-slider .item img {
  width: 35%;
  margin: 0 auto 15px auto;
}

/* Projects */
.project {
  padding: 88px 0;
  position: relative;
  background-color:#361063;
  color: aliceblue;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  color: aliceblue;
}

.project p {
  color: aliceblue;
  font-size: 18px;
  letter-spacing: .8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 55px;
  background-color: rgb(255, 255, 255 /10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 100%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: .8px;
  font-weight: 500;
  position: relative;
  transition: ease-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: .3s;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100%;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 55px 0px 0px 55px;
}

/* .nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, .5);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
} */
.nav-link#projects-tabs-tab-second {
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 0px 55px 55px 0px;
}

/* .nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 0px 55px 55px 0px;
} */

#imgLinks:link, #imgLinks:visited {
  text-decoration: none;
  color: white;
}
.proj-imgbx {
  background-color: white;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 100%;
  height: 100%;
}

.proj-imgbx::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background: linear-gradient(90.21deg, #aa367C -5.91%, #4A2FBD 111.58%);
  transition: .4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: .8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
/* about me*/

.about {
  background-color: blueviolet;
  padding: 5%;
}
.aboutContainer {
  border: 1px solid 433370;
  border-radius: 30px;
  background-color: #433370;
  color: white;
  padding: 5%;
}
.aboutMeImg {
  width: 300px;
  height: auto;
  border-radius: 30px;
}

/* footer */

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 50px 0;
  background-size: cover;
  background-color: #282c34;
  color: white;
}
/* Modal */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0%;
  padding-top: 10%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-header{
  background-color: #4A2FBD;
}
.modal-footer{
  background-color: #4A2FBD;
}

.modal-body {
  background-color: #361063;
  margin: 2% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 90%; /* Could be more or less, depending on screen size */
  border-radius: 5%;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* email form */

.formContainer{
  width: 30%;
  display: flex;
  justify-content: center;
  
}
#name, #eMail, #mess, #submitFormButton{
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}
.input{
  width: 100%;
  border-radius: 5%;
  
}

.input,
textarea {
  width: 100%;
  margin: 10px;
}

.formButton{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2%;
}
#submitButton {
  border-radius: 10px;
  padding: 5px 25px 5px 25px;
}
#formConfirmation{
  color: white;
}

/* section style */
.waveSections {
  position: relative;
  background: #2c3e50;
  height: 50vh;
}

.wave {
  position: absolute;
  height: 90px;
  width: 100%;
  background: #2c3e50;
  bottom: 0;
}

.wave::before, .wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
}

.wave::before {
  width: 55%;
  height: 109%;
  background-color: #fff;
  right: -1.5%;
  top: 60%;
}
.wave::after {
  width: 55%;
  height: 100%;
  background-color: #2c3e50;
  left: -1.5%;
  top: 40%;
}